import React, {Fragment} from 'react';
import {navigate, Link} from 'gatsby';
import {DialogWrapper, Button, Title} from './DialogWidgets';
import * as Ant from 'antd';
import {useOutlet} from 'reconnect.js';

function SearchDialog(props) {
  const [actions] = useOutlet('actions');
  const [dimension] = useOutlet('dimension');
  const [articles, setArticles] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [dialogVisible, setDialogVisible] = React.useState(false);

  React.useEffect(() => {}, [actions, props.searchValue]);

  async function searchArticle() {
    actions.setLoading(true);
    let resp = await actions.fetchDocuments('Article_Default', {
      title: {$regex: searchValue},
    });
    let dataList = resp.results;
    setDialogVisible(true);
    setArticles(dataList);
    actions.setLoading(false);
  }

  return (
    <>
      <Ant.Input.Search
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="SEARCH"
        onSearch={searchArticle}
        style={{
          width: dimension.rwd === 'desktop' ? 180 : 150,
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          top: '50%',
          right: dimension.rwd === 'desktop' ? -75 : -10,
        }}
      />
      <Ant.Modal
        visible={dialogVisible}
        title={<Title>搜尋結果: {searchValue}</Title>}
        footer={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}>
            <Button css="margin: 10px;" onClick={() => setDialogVisible(false)}>
              確定
            </Button>
          </div>
        }
        closeIcon={<p>X</p>}
        onCancel={() => setDialogVisible(false)}
        width="80%">
        <DialogWrapper>
          <div
            className="content"
            style={{whiteSpace: 'pre-wrap', paddingTop: 5, overflowY: 'auto'}}>
            {(() => {
              if (searchValue === '') {
                return (
                  <div style={{color: '#b02b4c'}}>
                    無文章標題符合 “{searchValue}”
                  </div>
                );
              } else if (articles.length === 0) {
                return (
                  <div style={{color: '#b02b4c'}}>
                    無文章標題符合 “{searchValue}”
                  </div>
                );
              } else {
                return articles.map((item, idx) => (
                  <div
                    onClick={() => {
                      navigate(`/article?id=${item.id}`);
                      setDialogVisible(false);
                    }}
                    className="grid-item-large"
                    key={idx}
                    style={{
                      display: 'flex',
                      flexDirection:
                        dimension.rwd === 'desktop' ? 'row' : 'column',
                      marginBottom: dimension.rwd === 'desktop' ? 50 : 0,
                    }}>
                    <div
                      className="grid-item-img-large"
                      style={{
                        backgroundImage: `url(${item.image})`,
                      }}
                    />
                    <div className="grid-context-container">
                      <p className="grid-item-title">{item.title}</p>
                      <p className="grid-item-date" style={{marginTop: 10}}>
                        {new Date(item.created).toLocaleDateString('en-ZA')}
                      </p>
                      <p
                        className="grid-item-outline outline"
                        style={{marginTop: 10}}>
                        {item.outline}
                      </p>
                    </div>
                  </div>
                ));
              }
            })()}
          </div>
        </DialogWrapper>
      </Ant.Modal>
    </>
  );
}

export default SearchDialog;
