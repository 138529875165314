import React from 'react';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import {useOutlet} from 'reconnect.js';
import 'antd/dist/antd.less';
import './Layout.css';
import AdminLayout from './AdminLayout';
import ProfileLayout from './ProfileLayout';
import CheckoutLayout from './CheckoutLayout';
import SocialFloatingBtn from './SocialFloatingBtn';
import SiteNavBar from './SiteNavBar';
import SiteFooter from './SiteFooter';

function Layout({children, location}) {
  const [dimension] = useOutlet('dimension');

  if (location.pathname.indexOf('admin') > -1) {
    return <AdminLayout location={location}>{children}</AdminLayout>;
  } else if (location.pathname.indexOf('profile') > -1) {
    return (
      <Wrapper rwd={dimension.rwd} dimension={dimension}>
        <SiteNavBar />
        <ProfileLayout location={location}>{children}</ProfileLayout>
      </Wrapper>
    );
  } else if (location.pathname.indexOf('checkout') > -1) {
    return (
      <Wrapper rwd={dimension.rwd} dimension={dimension}>
        <SiteNavBar />
        <CheckoutLayout location={location}>{children}</CheckoutLayout>
      </Wrapper>
    );
  }

  let hasSiteNavBar = true;
  // if (
  //   ['/'].indexOf(location.pathname) > -1 ||
  //   location.pathname.indexOf('/promo') === 0
  // ) {
  //   hasSiteNavBar = false;
  // }

  return (
    <>
      <Helmet>
        <title>微媞時尚診所</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com"></link>
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossorigin></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap"
          rel="stylesheet"></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Serif+TC&display=swap"
          rel="stylesheet"></link>
      </Helmet>
      <Wrapper rwd={dimension.rwd} dimension={dimension}>
        {hasSiteNavBar && <SiteNavBar />}
        {children}
        <SiteFooter />
        <div style={{}}>
          <SocialFloatingBtn />
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  --contentMaxWith: 1340px;
  --topNavBarHeight: 100px;
  --basePadding: 20px;
  --contentTextSize: 16px;
  --titleTextSize: ${(props) => (props.rwd === 'desktop' ? '20px' : '16px')};
  --contentTextColor: #434343;
  --contentTextLetterSpacing: 2px;
  --primaryColor: #b02b4c;
  --primaryFontColor: #434343;
  --secondFontColor: #6b6e6a;
  --sectionPadding: 50px 50px;
  --sectionMobilePadding: 40px 20px;

  background-color: #f6f6f6;
  .centered {
    margin: 0 auto;
    text-align: center;
  }

  .line {
    background-color: #cccccc;
    height: 1px;
  }

  .row {
    display: flex;
    flex-direction: row;
  }

  .section {
    max-width: var(--contentMaxWith);
    margin: 0 auto;
    padding: ${(props) =>
      props.rwd === 'desktop'
        ? 'var(--sectionPadding)'
        : 'var(--sectionMobilePadding)'};
  }

  .constraint {
    max-width: var(--contentMaxWith);
    margin: 0 auto;
    padding: ${(props) =>
      props.rwd === 'desktop'
        ? 'var(--sectionPadding)'
        : 'var(--sectionMobilePadding)'};
  }

  .light-bkg {
    background-color: #fbfbfb;
  }

  .title {
    font-family: 'Noto Serif TC';
    font-weight: 450;
    white-space: break-spaces;
    font-size: ${(props) => (props.rwd === 'desktop' ? 24 : 16)}px;
    color: var(--primaryFontColor);
    letter-spacing: 3px;
    ${(props) => {
      console.log('props.dimension titletitle', props.dimension);
    }}
  }

  .content {
    font-size: ${(props) => (props.rwd === 'desktop' ? 16 : 14)}px;
    letter-spacing: 2px;
    line-height: 26px;
    white-space: break-spaces;
  }

  .primary-color {
    color: var(--primaryColor);
  }

  .subtitle {
    color: var(--primaryColor);
    font-size: ${(props) => (props.rwd === 'desktop' ? 14 : 10)}px;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .grid {
    display: flex;
    flex-direction: ${(props) => (props.rwd === 'mobile' ? 'column' : 'row')};
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .grid-item-large {
    display: flex;
    flex-direction: ${(props) => (props.rwd === 'desktop' ? 'row' : 'column')};
    flex: 1;
    margin-top: 30px;
    cursor: pointer;
  }

  .grid-item {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    cursor: pointer;
    width: ${(props) => {
      if (props.rwd === 'mobile') {
        return `none`;
      } else if (props.rwd === 'desktop') {
        return `${(1240 - 100 - 90) / 3}px`;
      } else {
        return `${(props.dimension.innerWidth - 40 - 30) / 3}px`;
      }
    }};
  }

  .grid-item-img {
    /* flex-basis: ${(props) =>
      props.rwd === 'desktop' ? '400px' : '200px'}; */
    height: ${
      (props) => {
        if (props.rwd === 'desktop') {
          return '250px';
        } else if (props.rwd === 'pad') {
          return `${(((props.dimension.innerWidth - 60) / 3) * 5) / 7}px`;
        } else {
          return `${((props.dimension.innerWidth - 40) * 5) / 7}px`;
        }
      }
      // ((props.rwd === 'desktop' || props.rwd === 'pad') ? `${(props.dimension.innerWidth - 60)/3 * 5/7}px` : `${(props.dimension.innerWidth - 40) * 5/7}px`)
    }; // keep 7:5 ratio
    max-width: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: ${(props) => (props.rwd === 'desktop' ? '20px' : '10px')};
  }

  .grid-item-img-large {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-basis: ${(props) => (props.rwd === 'desktop' ? '370px' : '200px')};
    min-width: ${(props) => (props.rwd === 'desktop' ? '370px' : '200px')};
    min-height: 277px; // to make ratio 4:3
    border-radius: 10px;
  }

  .grid-context-container {
    padding: ${(props) =>
      props.rwd === 'desktop' ? '40px 50px 50px 50px' : '10px'};
  }

  .grid-item-date {
    color: var(--primaryColor);
    font-size: 13px;
    letter-spacing: 2px;
  }
  .grid-item-title {
    flex: 1;
    font-weight: 500;
    font-size: var(--titleTextSize);
    color: var(--primaryColor);
    letter-spacing: 2.57px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .grid-item-outline {
    flex: 1;
    font-size: var(--contentTextSize);
    letter-spacing: 2px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
    min-height: 85px;
    line-height: 28px;
  }

  .tab-wrapper {
    display: flex;
    padding: 20px 30px;
    border-radius: 8px;
    background-color: white;
    overflow-x: scroll;
    justify-content: ${(props) =>
      props.rwd === 'desktop' ? 'space-evenly' : 'flex-start'};
  }

  .tab {
    padding: 3px 10px;
    font-size: var(--contentTextSize);
    white-space: nowrap;
    cursor: pointer;
    letter-spacing: 1px;
  }

  .selected-tab {
    color: var(--primaryColor);
    font-size: var(--contentTextSize);
    font-weight: bold;
    border-bottom: 0.5px solid var(--primaryColor);
  }

  .scroll-constrain {
    overflow-x: auto;
    /* padding: ${(props) =>
      props.rwd === 'desktop' ? '40px 0px 40px 80px' : '40px 0px 0px 20px'}; */
  }

  .scroll-wrapper {
    display: -webkit-box;
  }
  .scroll-item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-right: 20px;
  }

  /* .scroll-item-img{
		height: 250px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		border-radius: 20px;
	}

  .scroll-item-subtitle{
		color: #666666;
		font-size: 13px;
		letter-spacing: 2px;
	}
	.scroll-item-title{
		font-size: 18px;
		letter-spacing: 2.57px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}  */
`;

export default Layout;
