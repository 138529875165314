import React from 'react';
import {getOutlet, useOutletSetter} from 'reconnect.js';
import styled from 'styled-components';
import {Button} from 'antd';
import SiteNavBar from './SiteNavBar';

function LoginRequired(props) {
  const {admin, location} = props;
  const showLoginModal = useOutletSetter('login-modal');
  let hasNavBar = true;

  if (location?.pathname.indexOf('admin') > -1) {
    hasNavBar = false;
  }

  return (
    <>
      {hasNavBar && <SiteNavBar />}
      <Wrapper>
        <img
          src="/images/NavLogo.png"
          alt="Logo"
          style={{width: 200, height: 150, objectFit: 'contain'}}
        />
        <h1 style={{margin: 20}}>微媞官網後台系統</h1>
        <Button onClick={() => showLoginModal({admin})}>登入</Button>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-80px);

  & > h1 {
    color: #4b4b4b;
  }
`;

function isAdmin(user) {
  const allowed = ['admin', 'management'];
  console.log('isAdmin', user);
  for (const grp of allowed) {
    if (user.grp.indexOf(grp) > -1) {
      return true;
    }
  }
  return false;
}

function withLoginRequired(NextComp, admin = false) {
  class Wrapper extends React.Component {
    constructor(props) {
      super(props);

      this._unregister = getOutlet('user').register(() => {
        this.forceUpdate();
      });
    }

    componentWillUnmount() {
      this._unregister();
    }

    render() {
      const user = getOutlet('user').getValue();

      console.log(user);

      if (user) {
        if (!admin || (admin && isAdmin(user))) {
          return <NextComp user={user} {...this.props} />;
        }
      }

      return <LoginRequired admin={admin} {...this.props} />;
    }
  }

  Wrapper.displayName = NextComp.displayName;

  return Wrapper;
}

export default LoginRequired;
export {withLoginRequired};
