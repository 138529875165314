import React, {Fragment} from 'react';
import {DialogWrapper, Button, Title} from './DialogWidgets';
import * as Ant from 'antd';

class TermsDialog extends React.Component {
  render() {
    let {closeDialog, show} = this.props;

    return (
      <Ant.Modal
        visible={show}
        title={<Title>微媞時尚診所 隱私權條款/免責聲明/個資使用說明</Title>}
        footer={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}>
            <Button css="margin: 10px;" onClick={() => closeDialog()}>
              確定
            </Button>
          </div>
        }
        closeIcon={<p>X</p>}
        onCancel={closeDialog}
        width="80%">
        <DialogWrapper>
          <div
            className="content"
            style={{whiteSpace: 'pre-wrap', paddingTop: 5}}>
            <p
              style={{
                whiteSpace: 'break-spaces',
              }}>{`歡迎您光臨「微媞時尚診所官方網站」(以下簡稱本網站)。本網站重視每一個使用者所享有的服務與權利，請您細讀本使用條款的內容及使用政策，以保障您的權益。`}</p>
            <p
              className="subtitle"
              style={{paddingLeft: 0, fontWeight: 'bold'}}>
              免責聲明
            </p>
            <p
              style={{
                whiteSpace: 'break-spaces',
              }}>{`一、本網站有權於任何時間修改或變更內容，建議您隨時注意該等修改或變更。您於任何修改或變更後繼續使用本網站，視為您已閱讀、瞭解並同意接受該等修改或變更。

一、本網站就使用或連結本網頁而生之任何損害（包括但不限於電腦病毒、系統固障、資料損失）、誹謗、侵犯版權或知識財產權所造成的損失，包括但不限於利潤、商譽、使用、資料損失或其他無形損失等，不負任何直接、間接、附帶、特別、衍生性或懲罰性賠償。

二、有關本公司醫療項目皆需經由現場專科執照人員，與貴賓當面依實際情況評估給予建議，同時說明其風險後向使用者本人確認及取得同意後方得已施行，本網站不保證所有服務項目之療效。

三、本網站可能會連接至其他機構所提供的網頁，但不會對這些網頁內容作出任何保證或承擔任何責任。如貴賓決定瀏覽這些網頁，亦或於本網站之服務中自行擷取、下載資料，請您考慮其風險，將自行承擔後果。因前述自行下載資料而導致使用者電腦系統之任何損壞或資料流失，本公司不承擔任何責任。

四、本公司所取得授權曝光之肖像權、圖文授權、影音授權等，經查證非經由本公司書面同意下第三方將其任意轉載、修改使用，此侵權行為及造成所有之損害，本公司不負任何賠償責任，同時視情節將依法通知並提出告訴。

五、本網站維護力求網站內容之準確性及完整性，如若內容有錯誤或遺漏，本網站不會承擔任何賠償責任，本網站所有內容將會隨時更新、或停止網頁資料，及有關條款修正而毋須事前通知使用者。

六、本免責聲明如有未盡事宜，應依中華民國衛生福利部或其他主管機關所制頒資訊使用管理辦法及中華民國法令之規定辦理。
`}</p>

            <p
              className="subtitle"
              style={{paddingLeft: 0, fontWeight: 'bold'}}>
              隱私權政策
            </p>

            <p
              style={{
                whiteSpace: 'break-spaces',
              }}>{`一、本公司遵守個人資料保護法之法令，但若因天災、事變或其他不可抗力所致者，以致您的個人資料被竊取、洩漏、竄改、遭其他侵害者，本公司將於查明後以電話、信函、電子郵件或網站公告等方法，將以適當方式通知貴賓您並保障您的權益。

二、為保護貴賓個人隱私，本公司無法為非本人查詢及提供其他貴賓的相關資料，請您見諒。若您有相關法律上問題需查閱他人資料時，請務必向國家行政機關辦理相關業務，本公司將依法配合有關單位協助調查。

三、由於本網站無法知悉所蒐集到的個人資料是否屬未成年人所有，或是有無得到其法定代理人的同意。如果您對本網站可能有未經法定代理人同意或未有其他法律依據而蒐集未成年人個人資料之疑慮，請您以本聲明所載之聯絡資訊聯繫本行，我們將立刻採取妥善措施進行調查及改正。

四、提醒貴賓應妥善保護個人資料。若您與他人共用電腦或使用公共硬體載具上瀏覽，請務必登出帳號及關閉流覽器視窗，以防止他人讀取您的信件或任何個人相關資料。

五、因應法令修正或時事之事由，本網站有權於任何時間修改或變更本政策之內容，貴賓應經常查看以瞭解您當前的權利及義務。若您於本網站為任何修改、或變更本政策後仍繼續使用本服務，將視為您已同意接受本政策之修改及變更。
`}</p>

            <p
              className="subtitle"
              style={{paddingLeft: 0, fontWeight: 'bold'}}>
              個資使用聲明
            </p>
            <p
              style={{
                whiteSpace: 'break-spaces',
              }}>
              {`一、	當您於本網站填寫個人資料，包括姓名、性別、聯絡方式（電話、E-Mail/LINE/ Wechat/諮詢內容）等相關基本資料，微媞時尚診所得依據中華民國「個人資料保護法」與相關法令之規範，蒐集、處理及利用您的個人資料。

二、	微媞時尚診所不會將您的個人資料為特定目的外之使用或是對外公開，在不涉及揭露您的個人身份的統計資料的前提下，您同意本網站為任何合法的使用。而當您的個人資料應用於符合《個人資料保護法》規定之例外情形時，本網站會檢視其適當性與合法性。

三、	本網站蒐集、處理、利用及保有個人資料之特定目的如下：微媞時尚診所將以您提供之資訊進行調查、分析用以管理您的使用者資料、客制化廣告及您看到的網頁內容、了解或改善您對服務的要求、客戶聯繫、進行研究，以及提供內部及外部客戶不記名之市場分析或統計報告，提供微媞時尚診所及相關單位進行行銷、問卷活動。

四、	您的個人資料將於前開蒐集之特定目的存續期間及依法令規定要求之期間內為本網站及微媞時尚診所所利用，且將於中華民國領域內及其他符合主管機關國際傳輸規定之其他地區利用。利用之對象為微媞時尚診所之關係企業、受微媞時尚診所委託處理事務之委外機構或單位、微媞時尚診所之協力廠商、與微媞時尚診所共同行銷或合作推廣業務或交互運用客戶資料之公司、您所同意之對象，及其他依法令規定利用之機構。微媞時尚診所將僅於前述特定目的範圍內，以符合個人資料保護法及相關法令之以自動化機器或其他非自動化之利用方式處理、利用您的個人資料。

五、	當您瀏覽或使用本公司網站提供之服務時，網站將留存由伺服器自行產生的相關紀錄，這些紀錄包括您瀏覽時間、項目、點選紀錄、使用連線設備的IP 位址、讀取時間、使用的瀏覽器等各項資訊。得以全球地區將資料用作以下用途：客制化廣告及您看到的網頁內容、了解或改善您對服務的要求、客戶聯繫、進行研究，以及提供內部及外部客戶不記名之市場分析或統計報告。如果您不希望本網站或關係企業利用這些資料，或與您進一步聯絡，請您事先告知，本公司會尊重您的意願。

六、	本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆有簽屬保密合約，如有違反保密義務者，將會受到相關的法律處分。如因評估需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且執行必要檢查程序以確定其將確實配合遵守。

六、 依據個資法第三條規定，您就本網站所保有之個人資料得行使下列權利：
  1. 請求本網站刪除、停止搜集、處理或利用。
  2. 請求本網站補充或更正，惟依法當事人應為之適當說明。
  於本網站查詢、請求閱覽或請求製給複製本，而本網站依法得酌收必要成本費用。若您欲行使上述之權利時，請與本公司連繫。但因您行使上述權利，而導致相關權益受損時，本公司將不負相關賠償責任。

七、	本網站的網頁亦提供其他網站的網路連結，貴賓也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。

八、 為了提供貴賓更優良的網站體驗，本網站會使用Cookie 技術，若您不希望使用Cookie，您可在所使用的瀏覽器功能項中設定隱私權等級為最高、或自行設定關閉即可。溫馨提醒您，關閉可能會導致網站部分功能無法正常執行。

九、 本網站絕不提供、交換、出租或出售，任何貴賓的個人資料給與其他個人、團體、私人企業或公務機關，但有合約義務者、司法機關或其他政府機關的要求、命令或依法令之規定而須提供者，不在此限。

十、 提醒貴賓應妥善保護個人資料。若您與他人共用電腦或使用公共硬體載具上瀏覽，請務必登出帳號及關閉流覽器視窗，以防止他人讀取您的信件或任何個人相關資料。

十一、 因應法令修正或時事之事由，本網站有權於任何時間修改或變更本聲明之內容，貴賓應經常查看以瞭解您當前的權利及義務。若您於本網站為任何修改、或變更本政策後仍繼續使用本服務，將視為您已同意接受本政策。
`}
            </p>
          </div>
        </DialogWrapper>
      </Ant.Modal>
    );
  }
}

export default TermsDialog;
