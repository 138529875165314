import React from 'react';
import styled from 'styled-components';
import {navigate, Link} from 'gatsby';
import {useOutlet} from 'reconnect.js';
import TermsDialog from './TermsDialog';
import {Facebook} from '@styled-icons/boxicons-logos';
import {TextAdd} from 'styled-icons/fluentui-system-regular';

function SiteFooter() {
  const [dimension] = useOutlet('dimension');
  const [openDialog, setOpenDialog] = React.useState(false);

  return (
    <>
      <Wrapper isMobile={!(dimension.rwd === 'desktop')}>
        <div
          className="content-section"
          style={{
            padding: 30,
            flexDirection: dimension.rwd === 'desktop' ? 'row' : 'column',
          }}>
          <div className="constrain">
            <h2 onClick={() => navigate('/')} style={{flex: 1}}>
              <img
                src="/images/FooterLogo.png"
                alt="Logo"
                style={{
                  height: dimension.rwd === 'desktop' ? 140 : 100,
                  objectFit: 'contain',
                }}
              />
            </h2>
            {/* <div style={{flex: 1}}></div> */}
            <div className="row">
              <div className="info">
                <div className="link-item" onClick={() => navigate('/')}>
                  首頁
                </div>
                <div className="link-item" onClick={() => navigate('/about')}>
                  關於我們
                </div>
                <div
                  className="link-item"
                  onClick={() => navigate('/articles')}>
                  最新消息
                </div>
                <div className="link-item" onClick={() => navigate('/team')}>
                  醫師團隊
                </div>
              </div>

              <div className="info">
                <div
                  className="link-item"
                  onClick={() => navigate('/services')}>
                  服務項目
                </div>
                <div className="link-item" onClick={() => navigate('/witness')}>
                  美麗見證
                </div>
                <div className="link-item" onClick={() => navigate('/contact')}>
                  聯絡我們
                </div>
              </div>
            </div>

            <div
              className="info"
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignSelf: 'stretch',
              }}>
              <a
                style={{color: '#434343'}}
                href="https://www.facebook.com/vbeautyTC"
                target="_blank"
                rel="noreferrer">
                <div className="link-item">
                  <Facebook
                    size={20}
                    style={{marginRight: 10, color: '#b02b4c'}}
                  />
                  {`微媞時尚診所 台中院     04 2328 8880`}
                </div>
              </a>
              <a
                style={{color: '#434343'}}
                href="https://www.facebook.com/vbclinic"
                target="_blank"
                rel="noreferrer">
                <div className="link-item">
                  <Facebook
                    size={20}
                    style={{marginRight: 10, color: '#b02b4c'}}
                  />
                  {`微媞時尚診所 台北院     02 2721 9663`}
                </div>
              </a>
              <div style={{flex: 1}} />
              <div
                // className="info"
                style={{
                  maxWidth: 280,
                  justifyContent:
                    dimension.rwd === 'desktop' ? 'flex-end' : 'center',
                  textAlign: 'right',
                }}>
                <a
                  href="https://www.youtube.com/channel/UCXfn2Ob1iDzajn-ZzT0sEmw"
                  target="_blank"
                  rel="noreferrer">
                  <img
                    src="/images/youtube.png"
                    alt="Logo"
                    style={{height: 40, objectFit: 'contain'}}
                  />
                </a>
                <a
                  href="https://www.instagram.com/vbeauty.group/"
                  target="_blank"
                  rel="noreferrer">
                  <img
                    src="/images/ig.png"
                    alt="Logo"
                    style={{height: 33, objectFit: 'contain', marginLeft: 15}}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-section">
          <div className="constrain" style={{color: '#979797'}}>
            <p>
              發佈內容已由當事人同意作為醫療資訊分享使用，治療效果因人而異，實際狀況須由專業醫師審慎評估後而定。本站禁止任何網際網路服務業者轉錄其網路資訊之內容供人點閱。但以網路搜尋或超連結方式，進入醫療機構之網址(域)直接點閱者，不在此限。版權所有
              © 微媞時尚診所《未經授權同意 請勿節錄或轉載本站圖文》
              <div style={{display: 'inline'}}>
                © 2021 V.Beauty Plastic Clinic all rights reserved.
              </div>
              <p
                onClick={() => setOpenDialog(true)}
                style={{
                  display: 'inline',
                  borderBottom: `1px solid #979797`,
                  paddingBottom: 2,
                  marginLeft: 20,
                  cursor: 'pointer',
                }}>
                定型化契約
              </p>
            </p>
          </div>
        </div>
      </Wrapper>
      <TermsDialog show={openDialog} closeDialog={() => setOpenDialog(false)} />
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  background-color: white;
  flex-direction: column;
  width: 100vw;

  & .constrain {
    margin: 0 auto;
    max-width: 1024px; //var(--contentMaxWith);
    display: flex;
    flex: 1;
    ${(props) => console.log('props', props)}
    flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
    align-items: center;
  }

  & > .bottom-section {
    background-color: #f6f6f6;
    padding: 25px 40px;
    font-size: 13px;
  }

  & > .content-section {
    display: flex;

    & .info {
      flex-basis: 100px;
      & img {
        cursor: pointer;
      }
    }
    & .link-item {
      min-width: 150px;
      padding: 10px 0px;
      cursor: pointer;
      transition: all 0.2s ease-out;
      white-space: nowrap;
      text-align: ${(props) => (props.isMobile ? 'center' : 'left')};
      color: #434343;

      :hover {
        color: #797979;
      }
    }

    & .social-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border: 1px solid #eee;
      border-radius: 20px;
    }
  }
`;

export default SiteFooter;
