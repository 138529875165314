import React from 'react';
import styled from 'styled-components';

const DialogWrapper = styled.div`
  background-color: white;
  & > .title {
    font-size: 20px;
    color: var(--primaryFontColor);
    padding: 10px 25px;
    border-top: 6px solid var(--primaryFontColor);
    border-bottom: 1px solid #eaeaea;
    border-radius: 5px 5px 0px 0px;
    letter-spacing: 2px;
  }

  // copy form Layout.js ---------------

  .grid-item-large {
    display: flex;
    flex-direction: ${(props) => (props.rwd === 'desktop' ? 'row' : 'column')};
    flex: 1;
    margin-top: 30px;
    cursor: pointer;
  }

  .grid-item-img {
    flex-basis: ${(props) => (props.rwd === 'desktop' ? '400px' : '200px')};
    height: ${(props) => (props.rwd === 'desktop' ? '250px' : '150px')};
    max-width: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: ${(props) => (props.rwd === 'desktop' ? '20px' : '10px')};
  }

  .grid-context-container {
    padding: ${(props) => (props.rwd === 'desktop' ? '50px' : '10px')};
    flex: 1;
  }

  .grid-item-date {
    color: var(--primaryColor);
    font-size: 13px;
    letter-spacing: 2px;
  }
  .grid-item-title {
    font-weight: 500;
    font-size: var(--titleTextSize);
    letter-spacing: 2.57px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .grid-item-outline {
    font-size: var(--contentTextSize);
    letter-spacing: 2px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
  }

  .grid-item-img-large {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-basis: ${(props) => (props.rwd === 'desktop' ? '400px' : '200px')};
    min-width: ${(props) => (props.rwd === 'desktop' ? '400px' : '200px')};
    border-radius: 10px;
  }

  //--------------------------------------------

  & .subtitle {
    font-size: 20px;
    padding: 15px 30px;
    letter-spacing: 2px;
  }

  & > .content {
    padding: 31px 23px;

    @media only screen and (max-width: 768px) {
      padding: 0px;
    }
  }

  & > .border-content {
    padding: 30px 40px;
    border-bottom: 1px solid #eaeaea;
  }

  & .row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    & > p {
      font-size: 13px;
      margin: 0;
      color: #555555;
    }

    & > h4 {
      text-align: left;
      min-width: ${(props) => props.labelWidth || '100px'};
      font-size: 13px;
      font-weight: 400;
      color: #555555;
      margin-bottom: 0;
    }

    & > h5 {
      min-width: 90px;
      font-size: 12px;
      font-weight: 600;
      color: #19233b;
      margin-bottom: 0;
    }
  }

  & .block {
    background-color: #f5f5f5;
    padding: 8px 15px;
    border-radius: 5px;
    margin-bottom: 15px;
  }

  ${(props) => props.css}
`;

const Input = styled.input`
  padding-left: 10px;
  width: 250px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #a6a6a6;

  :focus {
    outline: none;
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  width: 250px;
  border-radius: 5px;
  border: 1px solid #a6a6a6;

  :focus {
    outline: none;
  }
`;

const Selector = styled.select`
  height: 30px;
  width: 250px;
  color: #19233b;
  background-color: #fff;

  :focus {
    outline: none;
  }
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  background-color: ${(props) => props.color || 'var(--primaryFontColor)'};
  color: #fff;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
  font-size: 13px;
  margin: 10px;

  ${(props) => props.css}
`;

const Title = styled.div`
  font-size: 18px;
  letter-spacing: 2px;
  color: #555555;
`;

export {DialogWrapper, Input, Selector, Button, TextArea, Title};
