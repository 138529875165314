import React from 'react';
import styled from 'styled-components';
import {navigate, Link} from 'gatsby';
import * as Ant from 'antd';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import {Button} from 'antd';
import {Menu} from '@styled-icons/boxicons-regular';
import {Cart} from '@styled-icons/bootstrap/Cart';
import useBreakpoint from '../Hooks/useBreakPoint';
import SearchArticleInput from './SearchArticleInput';

const navItems = [
  {children: '關於我們', url: '/about'},
  {children: '最新消息', url: '/articles'},
  {children: '醫生團隊', url: '/team'},
  {children: '服務項目', url: '/services?cat=美容保養'},
  {children: '美麗見證', url: '/witness'},
  {children: '聯絡我們', url: '/contact'},
  {
    children: <Cart size={20} color="var(--primaryColor)" />,
    url: 'https://www.shopping-v.com',
  },
];

function SiteNavBar(props) {
  const [dimension] = useOutlet('dimension');
  const [showDrawer, setShowDrawer] = React.useState(false);
  const {passBreakpoint} = useBreakpoint(100);

  let pathname = '';
  if (typeof window !== 'undefined') {
    pathname = window.location.pathname;
  }
  console.log('location', pathname);

  function _isInSubPath({pathname, navItemUrl}) {
    // TODO: very buggy impl, change to a formal one
    // notice: we should also consider things like /products and /product?id=xx
    return (
      pathname.replace('/', '').slice(0, 3) ===
      navItemUrl.replace('/', '').slice(0, 3)
    );
  }

  return (
    <>
      <Wrapper>
        <div
          className="nav-content"
          style={{width: '100%', position: 'relative'}}>
          <SearchArticleInput />

          {dimension.rwd === 'desktop' ? (
            <div style={{display: 'flex', margin: '0 auto'}}>
              {navItems.slice(0, 3).map(({children, url}, idx) => {
                let selected = _isInSubPath({
                  pathname,
                  navItemUrl: url,
                });
                return (
                  <Link
                    to={url}
                    className="nav-link"
                    key={idx}
                    style={{color: selected ? '#b02b4c' : '#434343'}}>
                    {children}
                  </Link>
                );
              })}

              <h2 onClick={() => navigate('/')}>
                <img
                  src="/images/NavLogo.png"
                  alt="Logo"
                  style={{width: 154, height: 70, objectFit: 'contain'}}
                />
              </h2>

              {navItems.slice(3, 7).map(({children, url}, idx) => {
                let selected = _isInSubPath({
                  pathname,
                  navItemUrl: url,
                });
                return (
                  <Link
                    to={url}
                    className="nav-link"
                    key={idx}
                    style={{color: selected ? '#b02b4c' : '#434343'}}>
                    {children}
                  </Link>
                );
              })}
            </div>
          ) : (
            <div
              className="row"
              style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <h2 onClick={() => navigate('/')}>
                <img
                  src="/images/NavLogo.png"
                  alt="Logo"
                  style={{height: 50, objectFit: 'contain'}}
                />
              </h2>
              <div
                onClick={() => setShowDrawer(true)}
                style={{cursor: 'pointer'}}>
                <Menu size={30} />
              </div>
            </div>
          )}
        </div>
      </Wrapper>
      <Ant.Drawer
        placement="right"
        closable={false}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
        drawerStyle={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <img
          src="/images/NavLogo.png"
          alt="Logo"
          style={{width: 154, height: 50, objectFit: 'contain'}}
        />
        {navItems.map(({children, url}, idx) => {
          let selected = _isInSubPath({
            pathname,
            navItemUrl: url,
          });
          return (
            <div
              onClick={() => setShowDrawer(false)}
              style={{paddingTop: 40, textAlign: 'center'}}>
              <Link
                to={url}
                className="nav-link"
                key={idx}
                style={{color: selected ? '#b02b4c' : '#434343'}}>
                {children}
              </Link>
            </div>
          );
        })}
      </Ant.Drawer>
      <Placeholder />
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: var(--topNavBarHeight);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.1);
  transition: 200ms;
  z-index: 1;

  & > .nav-content {
    height: 100%;
    max-width: var(--contentMaxWith);
    padding: 0 15px;
    display: flex;
    align-items: center;

    & .nav-link {
      line-height: 30px;
      font-size: var(--contentTextSize);
      color: var(--contentTextColor);
      letter-spacing: var(--contentTextLetterSpacing);
      padding: var(--basePadding);
    }
  }
`;

const Placeholder = styled.div`
  position: static;
  height: var(--topNavBarHeight);
`;

export default SiteNavBar;
