// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-generators-admin-resource-index-js": () => import("./../../../src/Generators/AdminResource/index.js" /* webpackChunkName: "component---src-generators-admin-resource-index-js" */),
  "component---src-generators-promo-landing-index-js": () => import("./../../../src/Generators/PromoLanding/index.js" /* webpackChunkName: "component---src-generators-promo-landing-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-index-js": () => import("./../../../src/Templates/About/index.js" /* webpackChunkName: "component---src-templates-about-index-js" */),
  "component---src-templates-admin-articles-index-js": () => import("./../../../src/Templates/AdminArticles/index.js" /* webpackChunkName: "component---src-templates-admin-articles-index-js" */),
  "component---src-templates-admin-banner-index-js": () => import("./../../../src/Templates/AdminBanner/index.js" /* webpackChunkName: "component---src-templates-admin-banner-index-js" */),
  "component---src-templates-admin-image-list-index-js": () => import("./../../../src/Templates/AdminImageList/index.js" /* webpackChunkName: "component---src-templates-admin-image-list-index-js" */),
  "component---src-templates-admin-index-js": () => import("./../../../src/Templates/Admin/index.js" /* webpackChunkName: "component---src-templates-admin-index-js" */),
  "component---src-templates-admin-mails-index-js": () => import("./../../../src/Templates/AdminMails/index.js" /* webpackChunkName: "component---src-templates-admin-mails-index-js" */),
  "component---src-templates-admin-settings-index-js": () => import("./../../../src/Templates/AdminSettings/index.js" /* webpackChunkName: "component---src-templates-admin-settings-index-js" */),
  "component---src-templates-article-detail-index-js": () => import("./../../../src/Templates/ArticleDetail/index.js" /* webpackChunkName: "component---src-templates-article-detail-index-js" */),
  "component---src-templates-article-list-index-js": () => import("./../../../src/Templates/ArticleList/index.js" /* webpackChunkName: "component---src-templates-article-list-index-js" */),
  "component---src-templates-blog-detail-index-js": () => import("./../../../src/Templates/BlogDetail/index.js" /* webpackChunkName: "component---src-templates-blog-detail-index-js" */),
  "component---src-templates-cart-index-js": () => import("./../../../src/Templates/Cart/index.js" /* webpackChunkName: "component---src-templates-cart-index-js" */),
  "component---src-templates-checkout-info-index-js": () => import("./../../../src/Templates/CheckoutInfo/index.js" /* webpackChunkName: "component---src-templates-checkout-info-index-js" */),
  "component---src-templates-checkout-review-index-js": () => import("./../../../src/Templates/CheckoutReview/index.js" /* webpackChunkName: "component---src-templates-checkout-review-index-js" */),
  "component---src-templates-contact-index-js": () => import("./../../../src/Templates/Contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-forgot-password-confirm-page-js": () => import("./../../../src/Templates/ForgotPassword/ConfirmPage.js" /* webpackChunkName: "component---src-templates-forgot-password-confirm-page-js" */),
  "component---src-templates-forgot-password-request-page-js": () => import("./../../../src/Templates/ForgotPassword/RequestPage.js" /* webpackChunkName: "component---src-templates-forgot-password-request-page-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/Templates/Landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */),
  "component---src-templates-order-list-index-js": () => import("./../../../src/Templates/OrderList/index.js" /* webpackChunkName: "component---src-templates-order-list-index-js" */),
  "component---src-templates-product-detail-index-js": () => import("./../../../src/Templates/ProductDetail/index.js" /* webpackChunkName: "component---src-templates-product-detail-index-js" */),
  "component---src-templates-product-list-index-js": () => import("./../../../src/Templates/ProductList/index.js" /* webpackChunkName: "component---src-templates-product-list-index-js" */),
  "component---src-templates-profile-index-js": () => import("./../../../src/Templates/Profile/index.js" /* webpackChunkName: "component---src-templates-profile-index-js" */),
  "component---src-templates-register-confirm-page-js": () => import("./../../../src/Templates/Register/ConfirmPage.js" /* webpackChunkName: "component---src-templates-register-confirm-page-js" */),
  "component---src-templates-register-request-page-js": () => import("./../../../src/Templates/Register/RequestPage.js" /* webpackChunkName: "component---src-templates-register-request-page-js" */),
  "component---src-templates-services-index-js": () => import("./../../../src/Templates/Services/index.js" /* webpackChunkName: "component---src-templates-services-index-js" */),
  "component---src-templates-social-login-index-js": () => import("./../../../src/Templates/SocialLogin/index.js" /* webpackChunkName: "component---src-templates-social-login-index-js" */),
  "component---src-templates-team-index-js": () => import("./../../../src/Templates/Team/index.js" /* webpackChunkName: "component---src-templates-team-index-js" */),
  "component---src-templates-witness-index-js": () => import("./../../../src/Templates/Witness/index.js" /* webpackChunkName: "component---src-templates-witness-index-js" */)
}

